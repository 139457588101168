import { FormattedMessage } from 'react-intl'

const BubblesVerification = () => {
  return (
    <article id="bubbles-verification" className="bubbles">
      <div className="left-side">
        <span id="bubbles-verification-1" className="bubble">
          <FormattedMessage id="bubbles-verification-1" />
        </span>
        <span id="bubbles-verification-2" className="bubble">
          <FormattedMessage id="bubbles-verification-2" />
        </span>
      </div>
      <div className="right-side">
        <span id="bubbles-verification-3" className="bubble">
          <FormattedMessage id="bubbles-verification-3" />
        </span>
      </div>
    </article>
  )
}

export default BubblesVerification
