import CookieSettingsButton from '../CookieSettingsButton/CookieSettingsButton'

import './Cookies.css'

const Cookies = () => {
  return (
    <div className="legal-content" id="cookies">
      <h1>COOKIE POLICY</h1>
      <h3>Last update: December 1, 2024</h3>

      <p>
        This{' '}
        <strong>
          <a href="https://trueflag.ai/legal/cookie-preferences">
            Cookie Policy
          </a>{' '}
        </strong>
        is an integral part of the{' '}
        <a href="https://trueflag.ai/legal/terms-of-use">
          Terms and Conditions of Use
        </a>{' '}
        and{' '}
        <a href="https://trueflag.ai/legal/privacy-policy">Privacy Policy</a> of
        the website <strong>trueflag.ai</strong> (hereinafter, the “Website”).
        Accessing and browsing the Website, or using its services, implies
        acceptance of the terms and conditions set out therein.
      </p>

      <p>
        To facilitate navigation on the Website, INAR.IA LABS & TECHNOLOGIES,
        S.L. (hereinafter, “INARIA”), as the owner of the Website, with VAT
        number B70633235 and registered office at Calle Claudio Coello, 77, 4ª
        planta, 28001 Madrid, informs you that it uses cookies or other data
        storage and retrieval devices with similar functionality (hereinafter,
        “cookies”).
      </p>

      <p>
        In this regard, and to ensure that the user has all the necessary
        information for proper navigation, we provide the following informative
        text regarding what cookies are, what types of cookies are used on our
        Website and how they can be configured or disabled.
      </p>
      <CookieSettingsButton />
      <h2>1. WHAT IS A COOKIE?</h2>
      <p>
        A cookie is a small text file that a website, app or other platform
        stores on your computer, tablet, smartphone or any other similar device,
        with information on your browsing and use, like a tag that identifies
        your device.
      </p>
      <p>
        Cookies are necessary, for example, to facilitate browsing and
        understand how users interact with websites so they can be improved.
        They are also useful to provide advertising according to user
        preferences, as well as for other purposes detailed below. Cookies do
        not damage your computer or device.
      </p>
      <p>
        This explanation is a general overview of what cookies means and is for
        informational purposes only. The specific cookies we use are detailed in
        the cookies settings panel on our Website.
      </p>
      <h2>2. WHAT TYPE OF COOKIES ARE THERE?</h2>
      <p>
        Please check this section which provides an overview of the type of
        cookies that can be used in an online environment.
      </p>
      <p>Cookies can be classified as follows, depending on the owner:</p>
      <ul>
        <li>
          First-party cookies: Are sent to the user’s computer or device from a
          computer or domain managed by the editor, and which provides the
          platform or service requested by the user.
        </li>
        <li>
          Third-party cookies: Are sent to the user’s computer or device from a
          computer or domain not managed by the editor, but rather by another
          entity that processes data obtained from the cookies.
        </li>
      </ul>
      <p>
        Specifically, INARIA uses first-party cookies generated directly by this
        domain, and third-party cookies generated by external websites that are
        not part of INARIA, belonging to third-party companies, for the specific
        purposes outlined below. If, in the future, the provider uses other
        cookies to offer better or additional services, the user will be
        informed.
      </p>
      <p>Cookies can be classified as follows, depending on the purpose:</p>
      <ol style={{ listStyleType: 'lower-alpha' }}>
        <li>
          Strictly necessary cookies (technical): The cookies that allow the
          user to browse a website, platform or app and use the various options
          or services on it. For example, control traffic, identify data or
          session, access restricted access sections or content, remember the
          elements of an order, complete an order purchase process, manage
          payment, control fraud related to service security, use security
          elements during browsing, complete an application to register or
          participate in an event, store content for publishing videos and
          audio, enable dynamic content (for example, loading animation of a
          text or image) and share content on social media. As they are strictly
          necessary, technical cookies are downloaded by default when they are
          needed to display the platform or provide the service requested by the
          user. <br />
          INARIA does not require your consent to install on your device the
          cookies that are necessary to activate the features you use, as their
          use is based on the need to provide the requested services. If you
          disable or block all cookies in your browser, your navigation on the
          Website may not be optimal, and some features of the Website may not
          function properly.
        </li>
        <li>
          Functionality or customization cookies: These cookies are needed to
          remember information so that the user can access the service or
          platform with specific characteristics that can differentiate their
          experience from that of other users. For example, language, number of
          results displayed when the user runs a search, appearance or content
          of the service based on the type of browser used, or the region from
          where the service is accessed, etc. Not accepting cookies may cause
          slow website performance or poorly adapted recommendations.
          <br />
          Preference cookies are installed by default when accessing the Website
          or when selecting a customization option to display content adapted to
          your needs. INARIA does not need your consent to use these cookies,
          and the purposes for which this information is used do not have a
          significant impact on your privacy. They are essential for making our
          content attractive, so their use is based on our legitimate interest.
        </li>
        <li>
          Analysis cookies: These cookies can quantify the number of users,
          sections visited on the Website and how users interact with it to
          carry out statistical measurement and analysis on use, in order to
          implement improvements based on the analysis of data on how users use
          the platform or service.
          <br />
          The information collected by these cookies does not identify visitors.
          All information collected is aggregated and therefore anonymous. The
          purpose of these cookies is to generate statistics and reports that
          help improve website performance.
        </li>
        <li>
          Behavioural advertising cookies: Are those which store information on
          user behaviour obtained from continuous observation of their browsing
          habits, which allows us to develop a specific profile for displaying
          advertising adapted to these habits. These cookies allow for the most
          effective management possible of any advertising space the editor has
          included directly or in collaboration with third parties.
        </li>
      </ol>
      <section className="table-wrapper">
        <table border={1} cellPadding={10} cellSpacing={0}>
          <thead>
            <tr>
              <th>COOKIES</th>
              <th>TYPE</th>
              <th>PURPOSE</th>
              <th>DURATION</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>didomi_token</td>
              <td>Cookie</td>
              <td>
                This cookie contains consent information for custom purposes and
                vendors, as well as Didomi-specific information (user ID, for
                instance).
              </td>
              <td>12 months</td>
            </tr>
            <tr>
              <td>didomi_token</td>
              <td>Web storage</td>
              <td>
                This cookie contains consent information for custom purposes and
                vendors, as well as Didomi-specific information (user ID, for
                instance).
              </td>
              <td>session / unknown</td>
            </tr>
            <tr>
              <td>euconsent</td>
              <td>Cookie</td>
              <td>
                Contains the IAB TCF consent string and consent information for
                all the standard IAB vendors and purposes.
              </td>
              <td>session / unknown</td>
            </tr>
            <tr>
              <td>_ga</td>
              <td>Cookie</td>
              <td>Used to distinguish users.</td>
              <td>24 months</td>
            </tr>
            <tr>
              <td>_ga_#</td>
              <td>Cookie</td>
              <td>Used to persist session state.</td>
              <td>24 months</td>
            </tr>
          </tbody>
        </table>
      </section>
      <CookieSettingsButton />
      <h2>3. INTERNATIONAL TRANSFERS</h2>
      <p>
        Some of the personal data obtained through third-party cookies will be
        subject to international transfers. In such cases, we guarantee that
        necessary safeguards will be taken to protect the confidentiality,
        integrity, and availability of the data.
      </p>
      <p>
        For more detailed information on how we process your personal data in
        collaboration with third parties and on data subject to international
        data transfers, please read our{' '}
        <a href="https://trueflag.ai/legal/privacy-policy">Privacy Policy</a>.
        available on our Website, and the privacy policies/privacy settings of
        these third-party collaborators, available on their platforms/websites.
      </p>
      <h2>4. HOW CAN I MANAGE THE USE OF COOKIES?</h2>
      <p>
        In the cookies settings panel, available at all times on our Website,
        you can find all the information on the cookies used by this Website,
        along with information on the purpose, duration and management (first or
        third-party) of each Cookie, so you can enable or disable the use of
        cookies that are not strictly necessary for Website functioning.
      </p>
      <p>
        Alternatively, if you are browsing the Internet, you can disable the use
        of cookies on your browser. Here is how to do this on the most popular
        browsers:
      </p>
      <ul>
        <li>
          <a
            target="_blank"
            href="https://support.google.com/chrome/answer/95647?hl=en"
            rel="noreferrer"
          >
            Chrome
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://support.apple.com/en-us/HT201265"
            rel="noreferrer"
          >
            Safari
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://support.mozilla.org/en-US/kb/disable-third-party-cookies"
            rel="noreferrer"
          >
            Firefox
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
            rel="noreferrer"
          >
            Internet Explorer
          </a>
        </li>
      </ul>
      <p>You can prevent the use of cookies at any time.</p>
      <p>
        Please remember that both managing the cookies settings panel and opting
        to reject cookies is specific to each browser you are using. Therefore,
        if you configure cookies one way on one device and want your option to
        apply equally to another device, you must enable the same option on the
        other device.
      </p>
      <p>
        Please remember that both managing the cookies settings panel and opting
        to reject cookies is specific to each browser you are using. Therefore,
        if you configure cookies one way on one device and want your option to
        apply equally to another device, you must enable the same option on the
        other device.
      </p>
      <ul>
        <li>
          Network Advertising Initiative (NAI) -{' '}
          <a
            target="_blank"
            href="https://www.networkadvertising.org/choices/"
            rel="noreferrer"
          >
            https://www.networkadvertising.org/choices/
          </a>
        </li>
        <li>
          Google Analytics -{' '}
          <a
            target="_blank"
            href="https://tools.google.com/dlpage/gaoptout"
            rel="noreferrer"
          >
            https://tools.google.com/dlpage/gaoptout
          </a>
        </li>
      </ul>
      <h2>5. USER’S RIGHTS AND ADDITIONAL INFORMATION</h2>
      <p>
        You can find more information about the processing of your data by INAR
        IA, as well as how to exercise your rights, in our{' '}
        <a href="https://trueflag.ai/legal/privacy-policy">Privacy Policy</a>.
        You can also contact INARIA at the email address:{' '}
        <a href="mailto:contact@trueflag.ai">contact@trueflag.ai</a>.
      </p>
    </div>
  )
}

export default Cookies
