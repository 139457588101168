import MarkdownMessage from '@gluedigital/markdown-message'

import video1Webm from 'src/static/videos/intro-video-1.webm'
import video1Mov from 'src/static/videos/intro-video-1.mp4'
import video2Webm from 'src/static/videos/intro-video-2.webm'
import video2Mov from 'src/static/videos/intro-video-2.mp4'
import './IntroCard.css'

const IntroCard = ({ cardId }: { cardId: number }) => {
  const video = {
    1: {
      videoWebm: video1Webm,
      videoMov: video1Mov
    },
    2: {
      videoWebm: video2Webm,
      videoMov: video2Mov
    }
  }
  return (
    <article id={`intro-card-${cardId}`} className="intro-card">
      <video
        id={`intro-video-${cardId}`}
        className="intro-video"
        autoPlay
        playsInline
        loop
        muted
        controls={false}
      >
        <source src={video?.[cardId]?.videoWebm} type="video/webm" />
        <source src={video?.[cardId]?.videoMov} type="video/quicktime" />
      </video>
      <p>
        <MarkdownMessage id={`intro-card.card-id-${cardId}`} />
      </p>
    </article>
  )
}

export default IntroCard
