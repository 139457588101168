import { FormattedMessage } from 'react-intl'
import { useDidomi } from '@hooks/useDidomi'

import './CookieSettingsButton.css'

const CookieSettingsButton = () => {
  const didomi = useDidomi()
  return (
    <button
      disabled={!didomi}
      id="cookie-settings-button"
      onClick={() => didomi?.preferences.show()}
    >
      <FormattedMessage id="cookie-settings" />
    </button>
  )
}

export default CookieSettingsButton
