import { useState, useEffect } from 'react'

export const useDidomi = () => {
  const [didomi, setDidomi] = useState<typeof window.Didomi | null>(null)

  useEffect(() => {
    if (window.Didomi) {
      setDidomi(window.Didomi)
      return
    }

    window.didomiOnReady = window.didomiOnReady || []
    window.didomiOnReady.push(() => {
      setDidomi(window.Didomi)
    })
  }, [])

  return didomi
}
