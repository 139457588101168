import icon from 'src/static/vectors/icon.svg'
import text from 'src/static/vectors/only-logo.svg'

import './LogoAnimation.css'

const LogoAnimation = () => {
  return (
    <div id="logo-animation">
      <div className="container">
        <img className="symbol" src={icon} alt="Trueflag symbol" />
        <img className="text" src={text} alt="Trueflag logo" />
      </div>
    </div>
  )
}

export default LogoAnimation
