import { RefObject } from 'react'
import { FormattedMessage } from 'react-intl'
import { useAtTop } from '@gluedigital/scrollastic'

import './ScrollDown.css'

interface ScrollDownProps {
  scrollable: RefObject<HTMLDivElement>
}

const ScrollDown = ({ scrollable }: ScrollDownProps) => {
  const atTop = useAtTop(scrollable)
  return (
    <a href="#scroll-anchor" className={atTop ? 'at-top' : ''} id="scroll-down">
      <div id="mouse-body">
        <div id="mouse-wheel" />
      </div>
      <strong id="scroll-down-text">
        <FormattedMessage id="scroll-down" />
      </strong>
      <span className="icon icon-down" />
    </a>
  )
}

export default ScrollDown
