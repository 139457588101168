import { RefObject } from 'react'
import { FormattedMessage } from 'react-intl'
import { useScrollOver } from '@gluedigital/scrollastic'

import './Verification.css'

interface VerificationProps {
  scrollable: RefObject<HTMLDivElement>
}

function calculateWidth(scrollOver, start, end) {
  if (scrollOver < start) return 100
  if (scrollOver > end) return 0
  const totalRange = end - start
  const progressInRange = (scrollOver - start) / totalRange

  return 100 * (1 - progressInRange)
}

const Verification = ({ scrollable }: VerificationProps) => {
  const scrollOver = useScrollOver('#verification', scrollable)
  const sticky = scrollOver > 0.005 && scrollOver < 1
  return (
    <section
      className="landing-section landing-section-sticky"
      id="verification"
    >
      <div
        className={`landing-section-content ${sticky ? 'enter-sticky' : ''}`}
      >
        <div className="lines">
          <div className={`line ${scrollOver > 0.05 ? 'enter' : ''}`}>
            <span>
              <FormattedMessage id="verification.line-1" />
            </span>
            <div
              className="line-mask"
              style={{ width: calculateWidth(scrollOver, 0.2, 0.3) + '%' }}
            />
          </div>
          <div className={`line ${scrollOver > 0.1 ? 'enter' : ''}`}>
            <span>
              <FormattedMessage id="verification.line-2" />
            </span>
            <div
              className="line-mask"
              style={{ width: calculateWidth(scrollOver, 0.25, 0.4) + '%' }}
            />
          </div>
          <div className={`line ${scrollOver > 0.15 ? 'enter' : ''}`}>
            <span>
              <FormattedMessage id="verification.line-3" />
            </span>
            <div
              className="line-mask"
              style={{ width: calculateWidth(scrollOver, 0.35, 0.5) + '%' }}
            />
          </div>
          <div className={`line ${scrollOver > 0.175 ? 'enter' : ''}`}>
            <span>
              <FormattedMessage id="verification.line-4" />
            </span>
            <div
              className="line-mask"
              style={{ width: calculateWidth(scrollOver, 0.45, 0.6) + '%' }}
            />
          </div>
          <div className={`line ${scrollOver > 0.2 ? 'enter' : ''}`}>
            <span>
              <FormattedMessage id="verification.line-5" />
            </span>
            <div
              className="line-mask"
              style={{ width: calculateWidth(scrollOver, 0.55, 0.65) + '%' }}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Verification
