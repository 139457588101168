import { FormattedMessage } from 'react-intl'
import { SolutionsEnum } from 'src/types'
import TiltImage from '../TiltImage/TiltImage'
import Bubbles from '../TiltImage/Bubbles/Bubbles'

import './SolutionSlide.css'

interface SolutionSlideProps {
  solution: SolutionsEnum
  enter?: boolean
  index: number
  tilt?: boolean
}

const SolutionSlide = ({
  solution,
  enter = true,
  index,
  tilt
}: SolutionSlideProps) => {
  return (
    <section
      className={`solution-slide ${enter ? 'enter' : ''}`}
      id={`solution-slide-${solution}`}
    >
      <div className="content">
        {tilt ? (
          <TiltImage
            id={solution}
            backgroundImage={`/images/landing/solutions/solution-vertical-${index + 1}.jpg`}
          />
        ) : (
          <div
            className="tilt-image"
            style={{
              backgroundImage: `url(/images/landing/solutions/solution-vertical-${index + 1}.jpg)`
            }}
          >
            <Bubbles id={solution} />
          </div>
        )}
        <h1>
          <FormattedMessage id={`solution-${solution}-title`} />
        </h1>
        <p>
          <FormattedMessage id={`solution-${solution}-text`} />
        </p>
      </div>
    </section>
  )
}

export default SolutionSlide
