import { RefObject, useEffect, useState } from 'react'
import { useScrollOver } from '@gluedigital/scrollastic'
import DataFact from './DataFact/DataFact'

import './DataFacts.css'

interface DataFactsProps {
  scrollable: RefObject<HTMLDivElement>
}

const DataFacts = ({ scrollable }: DataFactsProps) => {
  const scrollOver = useScrollOver('#data-facts', scrollable)
  const [visibleFacts, setVisibleFacts] = useState([false, false, false])

  useEffect(() => {
    const newVisibleFacts = [
      scrollOver >= 0.42,
      scrollOver >= 0.66,
      scrollOver >= 0.78
    ]
    setVisibleFacts(newVisibleFacts)
  }, [scrollOver])
  return (
    <section className="landing-section landing-section-sticky" id="data-facts">
      {[1, 2, 3].map((n, i) => (
        <DataFact key={n} dataId={n} revealed={visibleFacts[i]} />
      ))}
    </section>
  )
}

export default DataFacts
