import { FormattedMessage } from 'react-intl'
import { SolutionsEnum } from 'src/types'
import { RefObject } from 'react'
import { useScrollOver } from '@gluedigital/scrollastic'

import './Solutions.css'

interface SolitionsProps {
  scrollable: RefObject<HTMLDivElement>
}

const Solutions = ({ scrollable }: SolitionsProps) => {
  const scrollOver = useScrollOver('#solutions', scrollable)
  const sticky = scrollOver > 0.005 && scrollOver < 1
  return (
    <section className="landing-section landing-section-sticky" id="solutions">
      <div
        className={`landing-section-content ${sticky ? 'enter-sticky' : ''}`}
      >
        <div id="solutions-grid">
          {Object.values(SolutionsEnum).map((s, i) => (
            <article
              style={{
                backgroundImage: `url('/images/landing/solutions/solution-vertical-${i + 1}.jpg')`,
                transitionDelay: `${i * 0.2}s`
              }}
              className={`solution ${scrollOver > 0.2 ? 'enter' : ''}`}
              id={`solution-${s}`}
              key={s + i}
            >
              <a href={`./#anchor-slide-${s}`}>
                <h1>
                  <strong>{1 + i}</strong>
                  <FormattedMessage id={`solution-${s}-title`} />
                </h1>
              </a>
            </article>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Solutions
