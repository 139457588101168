import fallbackPosterPNG from 'src/static/images/landing/coming-soon/poster-radar.png'
import videoDesktop from 'src/static/videos/radar.mp4'
import videoMobile from 'src/static/videos/radar-mobile.mp4'

import './RadarAnimation.css'

const RadarAnimation = () => {
  return (
    <video id="radar-video" loop autoPlay playsInline muted controls={false}>
      <img src={fallbackPosterPNG} alt="Fallback poster" />
      <source src={videoMobile} type="video/mp4" media="(max-width: 540px)" />
      <source src={videoDesktop} type="video/mp4" media="(min-width: 541px)" />
    </video>
  )
}

export default RadarAnimation
