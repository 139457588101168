import Navigation from './Navigation'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import logo from 'src/static/vectors/logo.svg'
import logoW from 'src/static/vectors/logo-w.svg'
import ContactDialog from '@components/ContactDialog/ContactDialog'

import './Header.css'

const Header = ({
  simpleMode = false,
  section
}: {
  simpleMode?: boolean
  section?: string
}) => {
  const [openContact, setOpenContact] = useState(false)
  const [openNavigation, setOpenNavigation] = useState(false)
  if (simpleMode) {
    return (
      <>
        <header id="masthead" className={`simple-mode header-${section}`}>
          <div className="container">
            <a href="/#hero-animation">
              <img src={logo} alt="Trueflag logo" />
            </a>
            <a onClick={() => setOpenContact(true)} className="button">
              <FormattedMessage id="try-trueFlag" />
            </a>
          </div>
        </header>
        {openContact && (
          <ContactDialog open={openContact} handleOpen={setOpenContact} />
        )}
      </>
    )
  }
  return (
    <>
      <header id="masthead" className={`header-${section}`}>
        <div className="container">
          <a href="/#hero-animation">
            <img src={logo} alt="Trueflag logo" />
          </a>
          <Navigation />
          <section>
            <a onClick={() => setOpenContact(true)} className="button">
              <FormattedMessage id="try-trueFlag" />
            </a>
            <span
              className="only-responsive icon icon-plus"
              onClick={() => setOpenNavigation(true)}
            />
          </section>
        </div>
      </header>
      <div
        id="mobile-navigation"
        className={`only-responsive ${openNavigation ? 'open' : ''}`}
      >
        <div id="mobile-navigation-content">
          <span
            className="icon icon-close"
            onClick={() => setOpenNavigation(false)}
          />
          <img src={logoW} alt="Trueflag logo" />
          <Navigation />
        </div>
      </div>
      {openContact && (
        <ContactDialog open={openContact} handleOpen={setOpenContact} />
      )}
    </>
  )
}

export default Header
