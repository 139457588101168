import { useRef } from 'react'
import { useScrollSelector } from '@gluedigital/scrollastic'
import Footer from '@components/Footer/Footer'
import Header from '@components/Header/Header'
import Contact from './sections/Contact/Contact'
import Intro from './sections/Intro/Intro'
import ScrollDown from '@components/ScrollDown/ScrollDown'
import Designed from './sections/Designed/Designed'
import Verification from './sections/Verification/Verification'
import DataFacts from './sections/DataFacts/DataFacts'
import HeroAnimation from './sections/HeroAnimation/HeroAnimation'
import Solutions from './sections/Solutions/Solutions'
import SolutionSlides from './sections/SolutionSlides/SolutionSlides'

import './Landing.css'

const Landing = () => {
  const scrollable = useRef<HTMLDivElement>(null)
  const section = useScrollSelector('.landing-section', {
    scrollable,
    boundary: 0
  })
  return (
    <div id="landing" className={`page view-${section}`} ref={scrollable}>
      <Header simpleMode section={section} />
      <ScrollDown scrollable={scrollable} />
      <HeroAnimation />
      <Intro scrollable={scrollable} />
      <Verification scrollable={scrollable} />
      <Designed scrollable={scrollable} />
      <Solutions scrollable={scrollable} />
      <SolutionSlides scrollable={scrollable} />
      <DataFacts scrollable={scrollable} />
      <Contact />
      <Footer />
    </div>
  )
}

export default Landing
