import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import logoW from 'src/static/vectors/logo-w.svg'

import './Footer.css'

const Footer = ({ isFixed = false }: { isFixed?: boolean }) => {
  return (
    <footer className={isFixed ? 'is-fixed' : ''} id="colophon">
      <nav className="container">
        <ul>
          <li>
            <img src={logoW} alt="Trueflag logo" />
          </li>
          <li>
            <Link to="/legal/terms-of-use">
              <FormattedMessage id="footer.terms-of-use" />
            </Link>
          </li>
          <li>
            <Link to="/legal/privacy-policy">
              <FormattedMessage id="footer.privacy-policy" />
            </Link>
          </li>
          <li>
            <Link to="/legal/cookie-preferences">
              <FormattedMessage id="footer.cookie-preferences" />
            </Link>
          </li>
        </ul>
      </nav>
    </footer>
  )
}

export default Footer
