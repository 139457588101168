import MarkdownMessage from '@gluedigital/markdown-message'

import './DataFact.css'

const DataFact = ({
  dataId,
  revealed
}: {
  dataId: number
  revealed: boolean
}) => {
  return (
    <article
      id={`data-fact-${dataId}`}
      className={`data-fact ${revealed ? 'revealed' : ''}`}
    >
      <header>
        <h3>
          data fact <strong>0{dataId}</strong>
        </h3>
        <h2>
          <MarkdownMessage id={`data-fact.data-id-${dataId}`} />
        </h2>
        <div className="spaced" />
      </header>
      <section>
        <h1>
          <MarkdownMessage id={`data-fact.data-id-${dataId}`} />
        </h1>
      </section>
    </article>
  )
}

export default DataFact
