import { Routes, Route } from 'react-router-dom'
import Header from '@components/Header/Header'
import Footer from '@components/Footer/Footer'
import Privacy from './Privacy/Privacy'
import Terms from './Terms/Terms'
import Cookies from './Cookies/Cookies'
// import { useIntl } from 'react-intl'
// import TermsEs from './Terms/TermsEs'
// import CookiesEs from './Cookies/CookiesEs'
// import PrivacyEs from './Privacy/PrivacyEs'

import './Legal.css'
import NotFound from '@routes/NotFound/NotFound'

const Legal = () => {
  // const { locale } = useIntl()
  // const isSpanish = locale === 'es'
  return (
    <div id="legal" className="page">
      <Header simpleMode />
      <Routes>
        <Route path="/terms-of-use" element={<Terms />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/cookie-preferences" element={<Cookies />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  )
}

export default Legal
