import { SolutionsEnum } from 'src/types'
import SolutionSlide from '../SolutionSlide/SolutionSlide'

import './AnchorSlides.css'

const AnchorSlides = ({
  visible = false,
  activeSection
}: {
  visible?: boolean
  activeSection: string
}) => {
  const activeIndex = Object.values(SolutionsEnum).findIndex(
    (s) => `anchor-slide-${s}` === activeSection
  )
  return (
    <div id="anchor-slides">
      {Object.values(SolutionsEnum).map((s, i) => {
        return (
          <div key={s + i} className="anchor-slide" id={`anchor-slide-${s}`}>
            <SolutionSlide index={i} enter={true} solution={s} tilt={true} />
          </div>
        )
      })}
      {visible && activeIndex >= 0 && (
        <div id="anchor-bar">
          <div className="index-section">{`${activeIndex + 1}/4`}</div>
          <div className="anchors">
            {Object.values(SolutionsEnum).map((s, i) => {
              return (
                <a
                  href={`./#anchor-slide-${s}`}
                  className={`anchor-bar-slide ${activeIndex === i ? 'active' : ''}`}
                  id={`anchor-bar-slide-${s}`}
                  key={s + i}
                />
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default AnchorSlides
