import { useState, useRef, useCallback } from 'react'
import Bubbles from './Bubbles/Bubbles'
import { SolutionsEnum } from 'src/types'

import './TiltImage.css'

interface TiltImageProps {
  backgroundImage: string
  id: SolutionsEnum
}

const TiltImage = ({ backgroundImage, id }: TiltImageProps) => {
  const elementRef = useRef<HTMLDivElement>(null)
  const [transform, setTransform] = useState('')

  const handleMouseMove = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (!elementRef.current) return

    const rect = elementRef.current.getBoundingClientRect()
    const x = ((e.clientX - rect.left) / rect.width) * 2 - 1
    const y = ((e.clientY - rect.top) / rect.height) * 2 - 1

    const tiltX = Math.max(Math.min(y * -20, 20), -20)
    const tiltY = Math.max(Math.min(x * 20, 20), -20)

    setTransform(
      `perspective(1000px) rotateX(${tiltX}deg) rotateY(${tiltY}deg)`
    )
  }, [])

  const handleMouseLeave = useCallback(() => {
    setTransform('')
  }, [])

  return (
    <div
      ref={elementRef}
      className="tilt-image"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        transform: transform || ''
      }}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      <Bubbles id={id} />
    </div>
  )
}
export default TiltImage
