import { FormattedMessage } from 'react-intl'
import { useState } from 'react'
import MarkdownMessage from '@gluedigital/markdown-message'
import ContactDialog from '@components/ContactDialog/ContactDialog'

import './Contact.css'

const Contact = () => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <section className="landing-section" id="contact">
        <div className="container">
          <p>
            <MarkdownMessage id="landing-section.contact.text" />
          </p>
          <button onClick={() => setOpen(!open)}>
            <FormattedMessage id="contact-us" />
          </button>
        </div>
      </section>
      {open && <ContactDialog open={open} handleOpen={setOpen} />}
    </>
  )
}

export default Contact
