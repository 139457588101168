import { RefObject } from 'react'
import IntroCard from './IntroCard/IntroCard'
import { useScrollOver } from '@gluedigital/scrollastic'
import AnimatedFan from '@components/AnimatedFan/AnimatedFan'

import './Intro.css'

interface IntroProps {
  scrollable: RefObject<HTMLDivElement>
}

const Intro = ({ scrollable }: IntroProps) => {
  const scrollOver = useScrollOver('#intro', scrollable)
  // const [rotate, setRotate] = useState(0)
  // const calculateRotate = (scroll: number): number => {
  //   if (scroll <= 0.35) {
  //     return -95 + (scroll / 0.35) * 95
  //   } else {
  //     return 0
  //   }
  // }

  // useEffect(() => {
  //   setRotate(calculateRotate(scrollOver))
  // }, [scrollOver])

  const sticky = scrollOver > 0.005 && scrollOver < 1
  return (
    <section className="landing-section landing-section-sticky" id="intro">
      <div
        className={`landing-section-content ${sticky ? 'enter-sticky' : ''}`}
      >
        <div className="intro-fan-wrapper intro-fan-wrapper-left">
          <AnimatedFan
            type="top-right"
            auto={scrollOver > 0.05}
            backgroundColor="#DCEAFF"
          />
        </div>
        <div className="intro-fan-wrapper intro-fan-wrapper-right">
          <AnimatedFan auto={scrollOver > 0.08} backgroundColor="#EDF4FF" />
        </div>
        <div className="container">
          <section id="intro-cards">
            <div className={`left-wrapper ${scrollOver > 0.05 ? 'enter' : ''}`}>
              <IntroCard cardId={1} />
            </div>
            <div
              className={`right-wrapper ${scrollOver > 0.08 ? 'enter' : ''}`}
            >
              <IntroCard cardId={2} />
            </div>
          </section>
        </div>
      </div>
      <div id="scroll-anchor" />
    </section>
  )
}

export default Intro
