import { ChangeEvent, useState } from 'react'

interface ValidatedFieldProps {
  name: string
  type: string
  placeholder: string
  minLength: number
  title: string
}

const ValidatedField = ({
  name,
  type,
  placeholder,
  minLength,
  title
}: ValidatedFieldProps) => {
  const [value, setValue] = useState('')

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  return (
    <input
      name={name}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      pattern={`.{${minLength},}`}
      required
      title={title}
    />
  )
}

export default ValidatedField
