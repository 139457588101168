import { NavLink } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

const Navigation = () => {
  return (
    <nav>
      <ul>
        <li>
          <NavLink
            to={'/#hero-animation'}
            className={({ isActive }) => (isActive ? 'selected' : '')}
          >
            <FormattedMessage id="home" />
          </NavLink>
        </li>
        <li>
          <NavLink
            to={'/team'}
            className={({ isActive }) => (isActive ? 'selected' : '')}
          >
            <FormattedMessage id="team" />
          </NavLink>
        </li>
        {/* <li>
          <NavLink
            to={'/about'}
            className={({ isActive }) => (isActive ? 'selected' : '')}
          >
            <FormattedMessage id="about" />
          </NavLink>
        </li> */}
        {/* <li>
          <a href="/#contact">
            <FormattedMessage id="contact" />
          </a>
        </li> */}
      </ul>
    </nav>
  )
}

export default Navigation
