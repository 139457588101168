import { SolutionsEnum } from 'src/types'
import { RefObject } from 'react'
import { useScrollOver, useScrollSelector } from '@gluedigital/scrollastic'
import AnchorSlides from './AnchorSlides/AnchorSlides'
import SolutionSlide from './SolutionSlide/SolutionSlide'

import './SolutionSlides.css'

interface SolutionsProps {
  scrollable: RefObject<HTMLDivElement>
}

const SCROLL_THRESHOLDS = {
  [SolutionsEnum.MONITOR]: 0.07,
  [SolutionsEnum.DETECTION]: 0.326,
  [SolutionsEnum.VERIFICATION]: 0.51,
  [SolutionsEnum.ALERTS]: 0.711
}

const SolutionSlides = ({ scrollable }: SolutionsProps) => {
  const scrollOver = useScrollOver('#solution-slides', scrollable)
  const section = useScrollSelector('.anchor-slide', {
    scrollable,
    boundary: 0
  })
  const sticky =
    scrollOver >= SCROLL_THRESHOLDS[SolutionsEnum.MONITOR] && scrollOver < 1
  return (
    <section
      className="landing-section landing-section-sticky"
      id="solution-slides"
    >
      <div
        className={`landing-section-content ${sticky ? 'enter-sticky' : ''}`}
      >
        {Object.values(SolutionsEnum).map((s, i) => {
          const enter = scrollOver > SCROLL_THRESHOLDS[s]
          return (
            <SolutionSlide
              key={s + i}
              enter={enter}
              index={i}
              solution={s}
              tilt={true}
            />
          )
        })}
      </div>
      <AnchorSlides
        activeSection={section}
        visible={
          scrollOver > SCROLL_THRESHOLDS[SolutionsEnum.MONITOR] &&
          scrollOver <= SCROLL_THRESHOLDS[SolutionsEnum.ALERTS] + 0.1
        }
      />
    </section>
  )
}

export default SolutionSlides
