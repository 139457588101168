import './AnimatedFan.css'

const AnimatedFan = ({
  type = 'bottom-left',
  backgroundColor = '#DCEAFF',
  rotate,
  auto = false
}: {
  type?: 'bottom-left' | 'bottom-right' | 'top-left' | 'top-right'
  backgroundColor?: string
  rotate?: number
  auto?: boolean
}) => {
  return (
    <div
      style={{
        backgroundColor,
        transform: `${!auto ? `translate3d(0px, 0px, 0px) rotate(${rotate}deg)` : ''}`
      }}
      className={`animated-fan ${type} ${auto ? 'automatic' : ''}`}
    />
  )
}

export default AnimatedFan
