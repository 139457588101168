const BubblesMonitor = () => {
  return (
    <article id="bubbles-monitor" className="bubbles">
      <div className="left-side">
        <span className="bubble bubble-telegram">Telegram</span>
        <span className="bubble bubble-tiktok">TikTok</span>
      </div>
      <div className="right-side">
        <span className="bubble bubble-facebook">Facebook</span>
        <span className="bubble bubble-youtube">Youtube</span>
        <span className="bubble bubble-podcasts">Podcasts</span>
        <span className="bubble bubble-blogs">Blogs</span>
      </div>
    </article>
  )
}

export default BubblesMonitor
