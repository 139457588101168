import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'

import './TypingAnimation.css'

const TypingAnimation = ({ id }: { id: string }) => {
  const { formatMessage } = useIntl()
  const [text, setText] = useState('')
  const [index, setIndex] = useState(0)
  const [done, setDone] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      const formattedMessage = formatMessage({ id })
      if (index < formattedMessage.length) {
        setText((prevText) => prevText + formattedMessage[index])
        setIndex((prevIndex) => prevIndex + 1)
      } else {
        clearInterval(interval)
        setDone(true)
      }
    }, 85)

    return () => clearInterval(interval)
  }, [id, formatMessage, index])

  return (
    <h1 className={done ? 'done' : ''} id="typing-animation">
      {text}
    </h1>
  )
}

export default TypingAnimation
