import BubblesMonitor from './BubblesMonitor'
import BubblesDetection from './BubblesDetection'
import BubblesVerification from './BubblesVerification'
import BubblesAlerts from './BubblesAlerts'
import { SolutionsEnum } from 'src/types'

import './Bubbles.css'

interface BubblesProps {
  id: SolutionsEnum
}

const BUBBLES_COMPONENTS = {
  [SolutionsEnum.MONITOR]: BubblesMonitor,
  [SolutionsEnum.DETECTION]: BubblesDetection,
  [SolutionsEnum.VERIFICATION]: BubblesVerification,
  [SolutionsEnum.ALERTS]: BubblesAlerts
} as const

const Bubbles = ({ id }: BubblesProps) => {
  if (!(id in BUBBLES_COMPONENTS)) {
    console.warn(`Bubbles component with id "${id}" not found`)
    return null
  }

  const BubbleComponent = BUBBLES_COMPONENTS[id as SolutionsEnum]

  return <BubbleComponent />
}

export default Bubbles
