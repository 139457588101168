import { RefObject, useEffect, useRef, useState } from 'react'
import { useScrollOver } from '@gluedigital/scrollastic'
import AnimatedFan from '@components/AnimatedFan/AnimatedFan'

import dashboard from 'src/static/videos/dashboard.mp4'
import app from 'src/static/images/landing/designed/app-bg.jpg'

import './Designed.css'

interface DesignedProps {
  scrollable: RefObject<HTMLDivElement>
}

const Designed = ({ scrollable }: DesignedProps) => {
  const scrollOver = useScrollOver('#designed', scrollable)
  const [rotate, setRotate] = useState(0)
  const [hasPlayed, setHasPlayed] = useState(false)
  const videoRef = useRef<HTMLVideoElement>(null)
  const calculateRotate = (scroll: number): number => {
    if (scroll <= 0.35) {
      return -95 + (scroll / 0.35) * 95
    } else {
      return 0
    }
  }

  useEffect(() => {
    setRotate(calculateRotate(scrollOver))
    if (videoRef.current) {
      if (scrollOver > 0.3) {
        if (!hasPlayed) {
          videoRef.current.playbackRate = 0.65
          videoRef.current.play()
          setHasPlayed(true)
        }
      } else {
        videoRef.current.pause()
      }
      if (scrollOver < 0.1 || scrollOver > 0.99) {
        setHasPlayed(false)
        videoRef.current.currentTime = 0
      }
    }
  }, [scrollOver, hasPlayed])
  const sticky = scrollOver > 0.005 && scrollOver < 1
  return (
    <section className="landing-section landing-section-sticky" id="designed">
      <div
        className={`landing-section-content ${sticky ? 'enter-sticky' : ''}`}
      >
        <div className="designed-fan-wrapper designed-fan-wrapper-left">
          <AnimatedFan
            type="top-right"
            auto={scrollOver > 0.05}
            backgroundColor="#DCEAFF"
          />
        </div>
        <div className="designed-fan-wrapper designed-fan-wrapper-right">
          <AnimatedFan auto={scrollOver > 0.08} backgroundColor="#EDF4FF" />
        </div>
        <div className="container">
          <div
            className={`video-app-wrapper ${scrollOver > 0.1 ? 'enter' : ''}`}
          >
            <video
              ref={videoRef}
              id="app-video"
              playsInline
              muted
              controls={false}
            >
              <img src={app} alt="Fallback poster" />
              <source src={dashboard} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Designed
