import { FormattedMessage } from 'react-intl'

const BubblesMonitor = () => {
  return (
    <article id="bubbles-detection" className="bubbles">
      <div className="left-side">
        <span className="bubble">
          <FormattedMessage id="bubbles-detection-1" />
        </span>
        <span className="bubble">
          <FormattedMessage id="bubbles-detection-2" />
        </span>
        <span className="bubble">
          <FormattedMessage id="bubbles-detection-3" />
        </span>
        <span className="bubble">
          <FormattedMessage id="bubbles-detection-4" />
        </span>
        <span className="bubble">
          <FormattedMessage id="bubbles-detection-5" />
        </span>
        <span className="bubble">
          <FormattedMessage id="bubbles-detection-6" />
        </span>
        <span className="bubble">
          <FormattedMessage id="bubbles-detection-7" />
        </span>
      </div>
      <div className="right-side" />
    </article>
  )
}

export default BubblesMonitor
