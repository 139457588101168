import RadarAnimation from '@components/RadarAnimation/RadarAnimation'
import TypingAnimation from '@components/TypingAnimation/TypingAnimation'
import LogoAnimation from '@components/LogoAnimation/LogoAnimation'

import './HeroAnimation.css'

const HeroAnimation = () => {
  return (
    <section className="landing-section" id="hero-animation">
      <RadarAnimation />
      <TypingAnimation id="welcome-message" />
      <LogoAnimation />
    </section>
  )
}

export default HeroAnimation
