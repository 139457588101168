import { Routes, Route } from 'react-router-dom'
import Meta from '@components/Meta/Meta'
import ScrollToTop from '@components/ScrollToTop/ScrollToTop'
import Landing from './Landing/Landing'
import NotFound from './NotFound/NotFound'
import Legal from './Legal/Legal'
// import Team from './Team/Team'
import { DidomiSDK } from '@didomi/react'

const App = () => (
  <>
    <Meta />
    <ScrollToTop />
    <Routes>
      <Route path="/" element={<Landing />} />
      {/* <Route path="/team" element={<Team />} /> */}
      <Route path="/legal/*" element={<Legal />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
    <DidomiSDK
      noticeId="TCENLZHN"
      apiKey="8917a6d8-c64b-449d-b07a-54912600901b"
      gdprAppliesGlobally={true}
      onReady={(didomi) =>
        console.log('Didomi SDK is loaded and ready', didomi)
      }
      onConsentChanged={(cwtToken) =>
        console.log('A consent has been given/withdrawn', cwtToken)
      }
      onNoticeShown={() => console.log('Didomi Notice Shown')}
      onNoticeHidden={() => console.log('Didomi Notice Hidden')}
    />
  </>
)

export default App
