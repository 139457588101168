export enum SolutionsEnum {
  MONITOR = 'monitor',
  DETECTION = 'detection',
  VERIFICATION = 'verification',
  ALERTS = 'alerts'
}

export enum SlidesTypeEnum {
  TEAM = 'team',
  ADVISORS = 'advisors'
}
export type SlideType = TeamType | AdvisorType

export interface TeamType {
  image: string
  name: string
  summary: string
  id: string
  position: string
  linkedin?: string
  mail?: string
  fake: string
}

export interface AdvisorType {
  image: string
  name: string
  summary: string
  id: string
  position: string
}
