import './Terms.css'

const Terms = () => {
  return (
    <div className="legal-content" id="terms">
      <h1>Terms and Conditions of Use</h1>
      <h3>Last update: December 1, 2024</h3>
      <p>
        Accessing, browsing, and using the Website <strong>trueflag.ai </strong>
        (hereinafter, the “Website”) implies the express acceptance of these
        Terms and Conditions of Use (hereinafter referred to as “Terms and
        Conditions”), with the same validity and effect as any contract signed
        in writing.
      </p>
      <p>
        Compliance with these Terms and Conditions will be enforceable for
        anyone who accesses, browses, or uses the Website. If you do not agree
        with the following terms, please do not access, browse, or use any page
        of the Website.
      </p>
      <h2>1. OWNER OF THE WEBSITE </h2>
      <p>
        Owner: INAR. IA LABS & TECHNOLOGIES, S.L. (hereinafter referred to as
        “INARIA”).
      </p>
      <p>
        Registered offices: Calle Claudio Coello, 77, 4ª planta, 28001 Madrid
      </p>
      <p>VAT number: B70633235</p>
      <p>
        Public Registry: Mercantile Registry of Madrid, Volume 46413, Page 80,
        Sheet M815141
      </p>
      <p>
        Contact information: Calle Vandergoten, 1, 28014 Madrid or{' '}
        <a href="mailto:contact@trueflag.ai">contact@trueflag.ai</a>.
      </p>
      <h2>2. SCOPE OF APPLICATION</h2>
      <p>
        These Terms and Conditions regulate access to, browsing of, and use of
        the Website, without prejudice to INARIA’s right to modify the
        presentation, configuration, and content of the Website, as well as the
        conditions required for access and/or use. Accessing and using the
        Website content after the entry into force of any changes or
        modifications implies acceptance of the new terms.
      </p>
      <p>
        However, access to certain content and the use of specific services may
        be subject to particular conditions, which will, in all cases, be
        clearly shown and must be expressly accepted by users. These particular
        conditions may replace, supplement, or, where applicable, modify these
        general Terms and Conditions.
      </p>
      <p>
        INARIA reserves the right to modify the Terms and Conditions set forth
        herein, in whole or in part, by publishing any changes in the same
        manner as these Terms and Conditions appear, or through any
        communication directed at users.
      </p>
      <h2>3. INTELLECTUAL PROPERTY RIGHTS</h2>
      <p>
        INARIA is the owner, or where applicable, holds the corresponding
        licenses for the exploitation rights of the intellectual property of the
        Website, as well as the intellectual property rights over the
        information, materials, and content on it.
      </p>
      <p>
        Under no circumstances will access, browsing, and use of the Website by
        the user be understood as a waiver, transfer, license, or partial or
        total assignment of these rights by INARIA. The user has a right to use
        the content and/or services of the Website within a strictly domestic
        scope.
      </p>
      <p>
        References to registered trademarks or trade names, or other distinctive
        signs, whether owned by INARIA or third parties, implicitly carry a
        prohibition on their use without the consent of INARIA or the legitimate
        owners. Unless expressly stated otherwise, access, browsing, or use of
        the Website and/or its content does not grant the user any rights over
        the distinctive signs included therein.{' '}
      </p>
      <p>
        All intellectual property rights over the content and/or services of the
        Website are reserved, and in particular, it is prohibited to modify,
        copy, reproduce, publicly communicate, transform, or distribute, by any
        means and in any form, all or part of the content included on the
        Website, for public or commercial purposes, unless prior, express, and
        written authorization has been obtained from INARIA or, where
        applicable, from the holder of the corresponding rights.
      </p>
      <p>
        In the event that the user submits any information of any kind to INAR
        IA through any of the available channels, the user declares, guarantees,
        and accepts that they have the right to do so freely, that such
        information does not infringe any intellectual property, trade secret,
        or any other third-party rights, and that such information is not
        confidential or harmful to third parties.
      </p>
      <p>
        The user acknowledges and assumes responsibility, holding INARIA
        harmless, for any communication they personally or on their behalf
        provide, with this responsibility extending without limitation to the
        accuracy, legality, originality, and ownership of such communication.
      </p>
      <h2>4. LINKS</h2>
      <h3>4.1 LINKS TO OTHER WEBSITES</h3>
      <p>
        INARIA may provide users with various tools and applications that allow
        access to channels and pages on different websites, including social
        media platforms. The purpose of including these applications on the
        Website is solely to facilitate user access to those channels.
      </p>
      <p>
        In the event that the Website includes links to other websites through
        various buttons, links, banners, or embedded content, INARIA informs
        users that these links are directly managed by third parties. INARIA
        does not have the human or technical resources to know in advance,
        control, or approve all the information, content, products, or services
        provided by other websites that may be linked to from this Website.
      </p>
      <p>
        Under no circumstances does INARIA share any private information about
        its users with the owners of these external websites. Therefore, any
        information the user chooses to provide to these external platforms
        and/or websites is done at their own risk, and INARIA is not involved in
        this process.
      </p>
      <p>
        Since INARIA has no control over the content hosted on these channels,
        the user acknowledges and accepts that INARIA does not assume any
        responsibility for the content or services the user may access on these
        websites, nor for any content, issues regarding its operation, access,
        data, information, files, quality, and reliability of its products and
        services, advertisements, or any other materials available on them.{' '}
      </p>
      <p>
        Therefore, the user should exercise caution when evaluating and using
        the information, content, and services available on the linked channels,
        as well as when sharing their own or third-party information on those
        platforms. If the user accesses other websites through links on the
        Website, INARIA recommends reading the terms of use and privacy policies
        of those websites.
      </p>
      <p>
        If users become aware that activities carried out through these
        third-party websites are illegal or violate public morals and/or order,
        they should immediately inform INARIA so that the link to those websites
        can be disabled. This action will be carried out as soon as possible.
      </p>
      <p>
        In any case, the establishment of any type of link from the Website to
        an external website does not imply the existence of any kind of
        relationship, collaboration, or dependence between INARIA and the owner
        of the external website.
      </p>
      <h3>4.2 LINKS ON OTHER WEBSITES TO THE WEBSITE</h3>
      <p>
        INARIA does not authorize the establishment of links to this Website
        from other websites that contain illegal, unlawful, degrading, obscene
        materials, information, or content, or, in general, violate laws, public
        morals, public order, or generally accepted social norms.
      </p>
      <p>
        In any case, users may establish links to their respective websites only
        if they request prior and express authorization from INARIA.
      </p>
      <p>
        INARIA does not have the ability or human and technical resources to
        know, control, or approve all the information, content, products, or
        services provided by other websites that have established links to this
        Website. INARIA does not assume any responsibility for any aspect
        related to the websites that establish such links to this Website,
        including, but not limited to, their operation, access, data,
        information, files, quality, reliability of their products and services,
        their own links, and/or any of their content in general.
      </p>
      <h2>5. CONDITIONS OF THE USE OF THE WEBSITE</h2>
      <p>
        Accessing or using the Website for illegal or unauthorized purposes,
        whether for economic gain or not, is strictly prohibited. More
        specifically, and by way of example, but not limited to, the following
        actions are forbidden:
      </p>
      <ul>
        <li>
          Using the Website in any way that may cause damage, disruption,
          inefficiencies, or defects in its functionality or in the computer of
          a third party;
        </li>
        <li>
          Using the Website for the transmission, installation, or publication
          of any virus, malicious code, or other harmful programs or files;
        </li>
        <li>Using the Website to collect personal data from other users;</li>
        <li>
          Registering or communicating with INARIA through the Website using a
          false identity, impersonating others, or performing any action that
          may mislead other users regarding the origin of a message;
        </li>
        <li>
          Accessing any section of the Website, other systems or networks
          connected to any platform, any server of INARIA, or the services
          offered through the Website, through hacking, forgery, password
          extraction, or any other illegitimate means;
        </li>
        <li>
          Breaching or attempting to breach the security or authentication
          measures of the Website or any connected network, or the security or
          protection measures inherent in the content offered on the Website;
        </li>
        <li>
          Engaging in any activity that may cause disproportionate or
          unnecessary strain on the Website’s infrastructure or on INARIA’s
          systems or networks, as well as on the systems and networks connected
          to the Website;
        </li>
        <li>
          Preventing the normal development of an event, contest, promotion, or
          any other activity available on the Website or any of its features, by
          altering or attempting to alter, illegally or otherwise, the access,
          participation, or functioning of those activities, or by falsifying
          the results and/or using fraudulent participation methods, through any
          procedure, and/or through any practice that may undermine or violate
          these Terms and Conditions.
        </li>
      </ul>
      <h2>6. LIABILITY AND GUARANTEES</h2>
      <p>
        INARIA cannot guarantee the reliability, usefulness, accuracy, or
        truthfulness of all the information and/or services on the Website, nor
        the usefulness or truthfulness of the documentation made available
        through it.
      </p>
      <p>
        Consequently, INARIA does not guarantee or assume responsibility for:
        (i) the continuity of the Website’s content; (ii) the absence of errors
        in such content; (iii) the absence of viruses and/or other harmful
        components on the Website or on the server that hosts it; (iv) the
        invulnerability of the Website and/or the impossibility of breaching the
        security measures adopted for it; (v) the lack of usefulness or
        performance of the Website’s content; and (vi) any damage or loss
        caused, either to the user or to a third party, by anyone who violates
        the conditions, rules, or instructions set by INARIA on the Website or
        by breaching the Website’s security systems.
      </p>
      <p>
        However, INARIA declares that it has taken all necessary measures,
        within its possibilities and in line with current technological
        standards, to ensure the functioning of the Website and minimize system
        errors, both from a technical perspective and with regard to the content
        published on the Website.
      </p>
      <p>
        INARIA does not guarantee the legality, reliability, or usefulness of
        the content provided by third parties through the Website
      </p>
      <p>
        INARIA will not be responsible for the truthfulness, completeness, or
        updating of information published on the Website that comes from
        external sources (such as external news, reports from third-party
        professionals, etc.), nor for the content available on other websites
        through links from the Website.
      </p>
      <p>
        INARIA will not assume responsibility for any potential damage that may
        arise from using such information.
      </p>
      <p>
        If the user becomes aware of the publication of inaccurate, outdated, or
        false data, or of the existence of any illegal content, contrary to the
        law, or that could infringe intellectual or industrial property rights,
        data protection regulations, or any other rights, they must immediately
        notify INARIA so that appropriate measures can be taken.
      </p>
      <h2>7. PRIVACY POLICY AND COOKIES</h2>
      <p>
        In accordance with applicable data protection laws, all personal data
        provided during the use of the Website will be processed in compliance
        with the{' '}
        <a href="https://trueflag.ai/legal/privacy-policy">Privacy Policy</a>,
        which every user must expressly accept in order to use and register on
        the Website.
      </p>
      <p>
        By accepting these Terms and Conditions, the user is also explicitly,
        informedly, and unambiguously accepting our{' '}
        <a href="https://trueflag.ai/legal/privacy-policy">Privacy Policy</a>,
        and can exercise the rights that correspond to them under this policy,
        as explained in the aforementioned{' '}
        <a href="https://trueflag.ai/legal/privacy-policy">Privacy Policy</a>.
      </p>
      <p>
        Furthermore, users are informed that INARIA collects information about
        their browsing habits and the use they make of the Website, in
        accordance with the details provided in the{' '}
        <a href="https://trueflag.ai/legal/cookie-preferences">Cookie Policy</a>
        .
      </p>
      <h2>8. TERMINATION</h2>
      <p>
        Without prejudice to the eventual liability for damages that may arise,
        INARIA may, immediately and without prior notice, terminate the
        contractual relationship with the user. The company may interrupt user’s
        access to the Website or INARIA’s services if INARIA figures out that
        you are acting against these Terms and Conditions.{' '}
      </p>
      <h2>9. GOVERNING LAW AND JURISDICTION</h2>
      <p>
        These Terms and Conditions are governed by Spanish law. The parties
        expressly submit to the courts and tribunals of Madrid, waiving any
        other jurisdiction, for the resolution of disputes.{' '}
      </p>
      <p>
        For any claims regarding the use of our services, users may contact us
        through email or by mail to the address provided at the beginning, and
        we commit to seeking an amicable resolution to the conflict at all
        times.
      </p>
      <p>
        BY VISITING THIS WEBSITE, YOU (i) ACKNOWLEDGE THAT YOU HAVE READ AND
        FULLY UNDERSTAND THESE TERMS AND CONDITIONS; (II) ARE AWARE OF THE
        COMPULSORY NATURE OF THESE TERMS AND CONDITIONS; AND (III) FULLY ACCEPT
        ALL THE PROVISIONS SET FORTH HEREIN.
      </p>
    </div>
  )
}

export default Terms
