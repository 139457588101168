import { FormEvent, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { FORM_ENDPOINT, INDUSTRY_TAGS } from 'src/constants'
import Spinner from '@components/Spinner/Spinner'
import ValidatedField from './ValidatedField'
import check from 'src/static/vectors/check.svg'
import send from 'src/static/vectors/contact-send.svg'

import './ContactDialog.css'

type FormStatus = 'idle' | 'loading' | 'success' | 'error'

interface ContactDialogProps {
  open: boolean
  handleOpen: (o: boolean) => void
}

const ContactDialog = ({ open, handleOpen }: ContactDialogProps) => {
  const [formStatus, setFormStatus] = useState<FormStatus>('idle')
  const dialogRef = useRef<HTMLDialogElement>(null)
  const intl = useIntl()
  const privacyLink = (
    <Link to="/legal/privacy-policy">
      <FormattedMessage id="privacy-link" />
    </Link>
  )

  useEffect(() => {
    const dialog = dialogRef.current
    if (dialog) {
      if (open && !dialog.open) {
        dialog.showModal()
      } else {
        dialog.close()
      }
    }
  }, [open])

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = {
      ...Object.fromEntries(new FormData(e.currentTarget).entries()),
      subject: 'Contact from website Trueflag.ai'
    }
    setFormStatus('loading')
    try {
      const response = await fetch(FORM_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      })
      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`)
      }
      const result = await response.json()
      console.log('Success:', result)
      setFormStatus('success')
    } catch (error) {
      console.error('Error submitting form:', error)
      setFormStatus('error')
    }
  }

  const handleClose = () => {
    const dialog = dialogRef.current
    if (!open && dialog.open) {
      dialog.close()
    }
    handleOpen(false)
  }

  return (
    <dialog ref={dialogRef} id="contact-dialog">
      <header>
        <h1>
          <FormattedMessage id="contact-dialog.title" />
        </h1>
        <button onClick={handleClose}>
          <span className="icon icon-close" />
          <span>
            <FormattedMessage id="close" />
          </span>
        </button>
      </header>

      {formStatus === 'success' ? (
        <div className="success-feedback">
          <img className="symbol" src={send} alt="form sent" />
          <h1>
            <FormattedMessage id="send-success" />
          </h1>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <fieldset id="top-fieldset">
            <ValidatedField
              name="name"
              type="text"
              placeholder={intl.formatMessage({ id: 'placeholder.name' })}
              minLength={3}
              title={intl.formatMessage({ id: 'characters.title-3' })}
            />
            <ValidatedField
              name="lastname"
              type="text"
              placeholder={intl.formatMessage({ id: 'placeholder.lastname' })}
              minLength={3}
              title={intl.formatMessage({ id: 'characters.title-3' })}
            />
            <ValidatedField
              name="email"
              type="email"
              placeholder={intl.formatMessage({ id: 'placeholder.email' })}
              minLength={3}
              title={intl.formatMessage({ id: 'characters.title-3' })}
            />
            <ValidatedField
              name="phone"
              type="tel"
              placeholder={intl.formatMessage({ id: 'placeholder.tel' })}
              minLength={3}
              title={intl.formatMessage({ id: 'characters.title-3' })}
            />
            <ValidatedField
              name="company"
              type="text"
              placeholder={intl.formatMessage({ id: 'placeholder.company' })}
              minLength={3}
              title={intl.formatMessage({ id: 'characters.title-3' })}
            />
            <select required name="sector" defaultValue="">
              <option disabled value="">
                <FormattedMessage id="sector" />
              </option>
              {INDUSTRY_TAGS.map((o: string, i: number) => (
                <option key={o + i} value={o}>
                  {intl.formatMessage({ id: o })}
                </option>
              ))}
            </select>
          </fieldset>
          <fieldset id="bottom-fieldset">
            <textarea
              rows={6}
              minLength={10}
              name="message"
              placeholder={intl.formatMessage({ id: 'placeholder.message' })}
            />
            <label>
              <input required type="checkbox" name="subject" />
              <span>
                <img className="symbol" src={check} alt="Trueflag symbol" />
              </span>
              <span className="form-privacy">
                <FormattedMessage
                  id="form.privacy-1"
                  values={{ privacyLink }}
                />
              </span>
            </label>
            <span className="form-privacy">
              <FormattedMessage id="form.privacy-2" values={{ privacyLink }} />
            </span>
          </fieldset>
          <div className="feedback">
            {formStatus === 'error' && (
              <span className="error">
                <FormattedMessage id="send-error" />
              </span>
            )}
          </div>
          <button type="submit" disabled={formStatus !== 'idle'}>
            {formStatus !== 'loading' ? (
              <FormattedMessage id="send" />
            ) : (
              <Spinner />
            )}
          </button>
        </form>
      )}
    </dialog>
  )
}

export default ContactDialog
