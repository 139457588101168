export const FORM_ENDPOINT =
  'https://qgl5qdityl.execute-api.eu-west-1.amazonaws.com/dev'

export const INDUSTRY_TAGS = [
  'banking',
  'telecom',
  'health',
  'food',
  'fashion',
  'tourism',
  'energy',
  'other'
]
