import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import Footer from '@components/Footer/Footer'
import Header from '@components/Header/Header'

import fallbackPoster from 'src/static/images/not-found/poster-404.png'
import video404Webm from 'src/static/videos/not-found.webm'
import video404 from 'src/static/videos/not-found.mp4'
import './NotFound.css'

const NotFound = () => {
  return (
    <div id="not-found" className="page">
      <Header simpleMode />
      <div className="container">
        <video
          id="not-found-video"
          loop
          autoPlay
          playsInline
          muted
          controls={false}
        >
          <img src={fallbackPoster} alt="404 poster" />
          <source src={video404Webm} type="video/webm" />
          <source src={video404} type="video/mp4" />
        </video>
        <h1>
          <FormattedMessage id="not-found" />
        </h1>
        <Link className="button" to={'/'}>
          <FormattedMessage id="comeback-home" />
        </Link>
      </div>
      <Footer isFixed />
    </div>
  )
}

export default NotFound
