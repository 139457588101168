import './Privacy.css'

const Privacy = () => {
  return (
    <div className="legal-content" id="privacy">
      <h1>PRIVACY POLICY</h1>
      <h3>Last update: December 1, 2024</h3>
      <h2>1. WHO WILL PROCESS YOUR PERSONAL DATA?</h2>
      <p>
        Data Controller: INAR.IA LABS & TECHNOLOGIES, S.L. (hereinafter referred
        to as “INARIA”).
      </p>
      <p>
        Registered offices: Calle Claudio Coello, 77, 4ª planta, 28001 Madrid
        VAT number: B70633235
      </p>
      <p>
        Public Registry: Mercantile Registry of Madrid, Volume 46413, Page 80,
        Sheet M815141
      </p>
      <p>
        Address for communication purposes: Calle Vandergoten, 1, 28014 Madrid
      </p>
      <p>
        Email: <a href="mailto:contact@trueflag.ai">contact@trueflag.ai</a>
      </p>

      <h2>2. INFORMATION AND CONSENT</h2>
      <p>
        This{' '}
        <a href="https://trueflag.ai/legal/privacy-policy">Privacy Policy</a>{' '}
        together with the{' '}
        <a href="https://trueflag.ai/legal/terms-of-use">
          Terms and Conditions of Use
        </a>{' '}
        and{' '}
        <a href="https://trueflag.ai/legal/cookie-preferences">Cookie Policy</a>
        , sets forth the terms under which INARIA processes your information
        through the website <strong>trueflag.ai</strong> owned by INARIA
        (hereinafter, the “Website”).
      </p>
      <p>
        By accessing and using the Website, and by providing or permitting us to
        access any information that, by its nature, may identify you
        (hereinafter, “Personal Data”), whether for browsing purposes or for
        utilizing its services or functionalities, you agree to be bound by the
        Privacy Policy in effect at the time.
      </p>
      <p>
        We advise you to review this document regularly to ensure your full
        understanding and consent. The user should read this Privacy Policy,
        which has been written in a clear and simple manner to facilitate
        understanding, and voluntarily and freely decide whether to provide
        their personal data to INARIA.
      </p>
      <h2>3. PURPOSE OF DATA PROCESSING</h2>
      <p>
        The Personal Data provided through the Website will be processed by INAR
        IA for the purposes described below:
      </p>
      <ul>
        <li>
          To provide information about our business lines, products and
          services.
        </li>
        <li>To address any requests or inquiries made by the User.</li>
        <li>
          To generate anonymous statistical reports regarding user access
          patterns and activities on the Website.
        </li>
        <li>
          If the user consents, to send commercial communications about our
          products and services.
        </li>
      </ul>
      <h2>4. PERSONAL DATA THAT MAY BE PROCESSED BY INARIA</h2>
      <p>The following types of Personal Data may be processed by INARIA:</p>
      <ul>
        <li>Identifying Data: Name, surname.</li>
        <li>Contact Data: Email address, phone number.</li>
        <li>
          Browsing Data: The minimum data necessary to enable the use of the
          Website.
        </li>
      </ul>
      <h2>5. LEGAL BASIS FOR DATA PROCESSING</h2>
      <p>The legal basis for the processing of Personal Data is as follows:</p>
      <ul>
        <li>
          For managing inquiries, requests for information, complaints and
          claims, it will be the consent provided by the User.
        </li>
        <li>
          For generating anonymous statistical reports, it will be the
          legitimate interest of INARIA.
        </li>
        <li>
          For marketing purposes related to products and services offered by
          INAR AI to its clients, the processing will be based on the legitimate
          interest recognized by applicable regulations.
        </li>
      </ul>
      <p>
        The User may revoke their consent for any of the above-mentioned
        processing activities. However, such revocation will not affect the
        lawfulness of any processing carried out prior to the revocation.
      </p>
      <p>
        To withdraw consent, the user may contact INARIA in writing at Calle
        Claudio Coello, 77, 4º planta, 28001, Madrid, or via email at{' '}
        <a href="mailto:contact@trueflag.ai">contact@trueflag.ai</a>.
      </p>
      <h2>6. OBLIGATION TO PROVIDE DATA</h2>
      <p>
        The data requested in the forms on the Website is generally mandatory
        (unless otherwise indicated in a specific field) to fulfill the intended
        purposes. Therefore, if the required data is not provided or is provided
        inaccurately, the user’s requests may not be processed.
      </p>
      <h2>7. HOW LONG WILL WE RETAIN THE USER’S DATA?</h2>
      <p>
        We will retain and process the data for as long as necessary to fulfill
        the purposes for which it was collected, unless the user requests its
        deletion, objects to its processing, or withdraws their consent.
      </p>
      <p>
        For the formalization and management of product contracts, personal data
        will be retained for a period of 5 years from the termination of the
        contractual relationship, regardless of the reason for its termination.
      </p>
      <p>
        Upon completion of a processing activity, the information may be stored,
        properly blocked and only available to the competent authorities, as
        long as the responsibilities that may arise from the processing have not
        expired.
      </p>
      <h2>8. WHO WILL THE USER’S DATA BE SHARED WITH?</h2>
      <p>
        Unless otherwise stated, INARIA does not share data with third parties.
        However, if required by a contract binding the parties or by law, the
        data may need to be disclosed to third parties (e.g., courts and
        tribunals).
      </p>
      <p>
        Additionally, data may be shared with service providers who need access
        to data under INARIA’s responsibility. In all cases, INARIA will ensure
        that third parties it contracts with comply with applicable regulations
        and, under no circumstances, will the data be processed for purposes
        other than those specified by INARIA.
      </p>
      <h2>9. USER’S RESPONSIBILITY</h2>
      <p>
        The user guarantees that the data provided to INARIA is true, accurate,
        complete and up to date. To this end, the user is responsible for the
        truthfulness of all data communicated and will keep the information
        provided appropriately updated to reflect their actual situation.
      </p>
      <p>
        The user shall be responsible for any false or inaccurate information
        provided through the Website and for any direct or indirect damages or
        losses caused to INARIA or to third parties as a result.
      </p>
      <h2>10. USER’S RIGHTS</h2>
      <p>
        The user may send a written request to any of the addresses provided at
        the header of this policy to exercise their rights at any time, free of
        charge, by attaching a photocopy of their identity document, with the
        reference “Data Protection”.
      </p>
      <p>We inform you that the rights you may exercise are as follows:</p>
      <ul>
        <li>To withdraw any consents granted.</li>
        <li>
          To obtain confirmation as to whether or not personal data concerning
          the user is being processed by INARIA.
        </li>
        <li>To access their personal data.</li>
        <li>To rectify inaccurate or incomplete data.</li>
        <li>
          To request the deletion of their data when, among other reasons, the
          data is no longer necessary for the purposes for which it was
          collected.
        </li>
        <li>
          To request the restriction of data processing by INARIA when one of
          the conditions set out in data protection legislation is met.
        </li>
        <li>
          To request the portability of the data provided by the user in cases
          provided for by law.
        </li>
        <li>
          To file a complaint regarding the protection of their personal data
          with the Spanish Data Protection Agency (Agencia Española de
          Protección de Datos) at Calle de Jorge Juan, 6, 28001 Madrid, when the
          user believes that INARIA has violated the rights granted to them
          under applicable data protection legislation.
        </li>
      </ul>
      <h2>11. SECURITY MEASURES</h2>
      <p>
        INARIA will process the user&apos;s data at all times with the utmost
        confidentiality and will maintain its secrecy, in accordance with
        applicable regulations. To this end, INARIA will implement the necessary
        technical and organizational measures to ensure the security of the data
        and prevent its alteration, loss, processing, or unauthorized access,
        taking into account the state of technology, the nature of the stored
        data, and the risks to which they are exposed.
      </p>
    </div>
  )
}

export default Privacy
